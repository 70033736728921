import * as React from "react"
import {MyMenuContext} from "./PageWrapper"
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "../styles/modules/SideMenu.module.css"

const LogoMenuButton = ({altLogo}) => {
    const {toggleMenu} = React.useContext(MyMenuContext);
    return (
        <div onClick={toggleMenu} className={styles.menuButtonWrapper} >
            <StaticImage src="../images/icon.png" alt="Logo Marianne Alliot" layout="fixed" width={50} height={50} imgClassName={altLogo ? styles.altLogo : ""}/>
        </div>
    )
}

export default LogoMenuButton;